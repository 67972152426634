import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import ttGroup from "../../Assets/Projects/ttGroup.png";
import iflex from "../../Assets/Projects/iflex.jpg";
import ourclass from "../../Assets/Projects/ourclass.jpg";
import itadvancedis from "../../Assets/Projects/itadvancedis.jpg";
import zeracafe from "../../Assets/Projects/zeracafe.jpg";
import jewelry from "../../Assets/Projects/ELIZ_Jewelry.png";
import vitallife from "../../Assets/Projects/vitallife.jpg"
import bknorway from "../../Assets/Projects/bknorway.jpg"
import cospas from "../../Assets/Projects/cospas.jpg"
import mbrellafilms from "../../Assets/Projects/mbrellafilms.jpg"
import pbcert from "../../Assets/Projects/pbcert.jpg"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Jobs I <strong className="purple">Worked On </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={vitallife}
             isBlog={false}
             title='Vital Life - Bumrungrad Hospital '
             description='Kentico, C#, Javascript, Bootstrap'
             link='https://www.vitallifeintegratedhealth.com/en-US/Bangkrachao'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={bknorway}
             isBlog={false}
             title='BurgerKing Norway'
             description='Drupal, Php, Javascript, CSS'
             link='https://www.burgerking.no/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={cospas}
             isBlog={false}
             title='Cospas Sarsat INT'
             description='Joomla, PHP, Javascript, CSS'
             link='https://cospas-sarsat.int/en/'
           />
         </Col>

          {/* new */}
         
         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={pbcert}
             isBlog={false}
             title='Global Plant Base Certification'
             description='Next.js and Sanity '
             link='https://client.globalplantbased.com/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={ourclass}
             isBlog={false}
             title='Ourclasselc '
             description='Angular2 and Node.js'
             link='https://ourclasselc.com/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={iflex}
             isBlog={false}
             title='Iflex2u'
             description="Wordpress, PHP, JS, CSS "
             link='https://www.iflex2u.com/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={ttGroup}
             isBlog={false}
             title='TT Group - Construction Company'
             description='Wordpress, PHP, JS, CSS '
             link='https://www.ttgroup.co.th/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={mbrellafilms}
             isBlog={false}
             title='Mbrellafilms - Production House'
             description='Wordpress, PHP, JS, CSS '
             link='https://mbrellafilms.com/'
           />
         </Col>

         <Col md={4} className='project-card'>
           <ProjectCard
             imgPath={zeracafe}
             isBlog={false}
             title='Zeracafe'
             description='Wordpress, PHP, JS, CSS '
             link='https://zeracafe.ca/'
           />
         </Col>

      
       </Row>
      </Container>
    </Container>
  );
}

export default Projects;
