import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiNpm,
  SiPostman,
  SiHeroku,
  SiNetlify,
} from "react-icons/si";

import { DiGithubBadge } from "react-icons/di";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     <Col xs={4} md={2} className='tech-icons'>
       <DiGithubBadge />
     </Col>
     <Col xs={4} md={2} className='tech-icons'>
       <SiNetlify />
     </Col>
     <Col xs={4} md={2} className='tech-icons'>
       <SiNpm />
     </Col>
     <Col xs={4} md={2} className='tech-icons'>
       <SiHeroku />
     </Col>
     <Col xs={4} md={2} className='tech-icons'>
       <SiPostman />
     </Col>
    </Row>
  );
}

export default Toolstack;
